import React from "react"

import { Layout, SEO, SpecialMenu } from "src/components"
import { PATHS } from "../constants"

const SpecialPage = props => {
  const { location } = props
  const activeNavLink = PATHS[location.pathname]

  return (
    <Layout activeNavLink={activeNavLink}>
      <SEO title="New Golden Dragon | Special" />
      <SpecialMenu />
    </Layout>
  )
}

export default SpecialPage
